import i18next from 'i18next'
import I18NextVue from 'i18next-vue'
import { getCookie, getPlace, setCookie } from '@/lib/utils'
import zh from './locales/zh'
import en from './locales/en'
import ru from './locales/ru'

const SUPPORTED_LNGS = ['ru', 'en', 'zh']

i18next.init({
  lng: 'ru', // if you're using a language detector, do not define the lng option
  fallbackLng: 'ru',
  supportedLngs: SUPPORTED_LNGS,
  // debug: process.env.NODE_ENV === 'development',
  debug: false,
  resources: {
    ru,
    en,
    zh
  }
})

function detectUserLanguage() {
  const place = getPlace()
  let lng = getCookie(`language_${place}`) || navigator.language.substring(0, 2)
  if (!SUPPORTED_LNGS.includes(lng)) {
    lng = 'ru'
  }
  return lng
}

/**
 * Initializes the I18Next plugin for Vue.
 * @param {Vue} Vue Vue singleton to register I18NextVue on
 */
export function init(Vue) {
  Vue.use(I18NextVue, {i18next})
}

/**
 * This should be called if Object enables multiple languages.
 */
export function enableMultilang() {
  const lng = detectUserLanguage()
  i18next.changeLanguage(lng)
}

export function disableMultilang() {
  i18next.changeLanguage('ru')
}

export function saveLng(lng) {
  const place = getPlace()
  setCookie(`language_${place}`, lng, 365)
}

export function getSupportedLngs() {
  return SUPPORTED_LNGS
}

// expose for debug
window.i18next = i18next;
