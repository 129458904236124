/**
 * Mixin to reload data upon language change.
 * Host component must implement method with name passed as parameter.
 * @param {string} methodName Name of method in component to call upon Language change.
 */
export default function reloadData(methodName) {
  // don't use default parameter value to make relation 
  // between host component and mixin explicit
  if (!methodName) {
    throw new Error('reloadData mixin requires a method name to be passed as parameter!')
  }

  return {
    computed: {
      $i18nextLanguage() {
        return this.$i18next.language
      }
    },
    watch: {
      $i18nextLanguage: function () {
        if (typeof this[methodName] === 'function') {
          this[methodName]()
        } else {
          console.warn(`Component does not implement method named ${methodName}!`)
        }
      }
    }
  }
}