import { addPlaceToUrl } from '@/lib/utils'
import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
 
  {
    path: '/',
    name: 'main',
    meta: 'главная',
    component: () => import('../views/index.vue')
  },
  {
    path: '/home',
    name: 'home',
    meta: 'индекс',
    component: () => import('../views/index.vue')
  },
  {
    path: '/category/:id',
    name: 'category',
    meta: 'Меню в номер',
    component: () => import('../views/category.vue'),
  },
  {
    path: '/item/:id',
    name: 'item',
    meta: 'Блюдо',
    component: () => import('../views/item.vue')
  },
  {
    path: '/fourth',
    name: '/mob_fourth',
    meta: 'Четвертая',
    component: () => import('../views/fourth.vue')
  },
  {
    path: '/fifth',
    name: '/mob_fifth',
    meta: 'Пятая',
    component: () => import('../views/fifth.vue')
  },
  {
    path: '/sixth',
    name: '/mob_sixth',
    meta: 'Шестая',
    component: () => import('../views/sixth.vue')
  },
  {
    path: '/cart',
    name: 'cart',
    meta: 'Седьмая',
    component: () => import('../views/cart.vue')
  },
  {
    path: '/error',
    name: '/mob_error',
    meta: 'Ошибка',
    component: () => import('../views/error.vue')
  },
  {
    path: '/success',
    name: '/mob_success',
    meta: 'Успешно',
    component: () => import('../views/success.vue')
  },
  {
    path: '/paymenterror',
    name: '/payment_error',
    meta: 'Ошибка оплаты',
    component: () => import('../views/paymenterror.vue')
  },
  {
    path: '/checkout',
    name: 'checkout',
    meta: 'Оплатить',
    component: () => import('../views/checkout.vue')
  },
  {
    path: '/orderstatus/:id',
    name: 'orderstatus',
    meta: 'Статус заказа',
    component: () => import('../views/orderstatus.vue')
  },
  {
    path: '/callback',
    name: 'callback',
    meta: 'Статус оплаты заказа',
    component: () => import('../views/callback.vue')
  },

  {
    path: '/delivery/:id',
    name: 'delivery',
    meta: 'Статус заказа',
    component: () => import('../views/delivery.vue')
  },
  {
    path: '/checkdata/:id',
    name: 'checkdata',
    meta: 'Подтвердить оплату',
    component: () => import('../views/checkdata.vue')
  },
  {
    path: '/history',
    name: 'history',
    meta: 'История заказов',
    component: () => import('../views/history.vue')
  },
  // {
  //   path: '/ordercard',
  //   name: 'ordercard',
  //   meta: 'Оформить заказ',
  //   component: () => import('../components/card.vue')
  // },
  
  {
    path: '/checkdata',
    name: 'checkdata_empty',
    meta: 'Подтвердить оплату',
    component: () => import('../views/checkdata.vue')
  },
   {
    path: '/details',
    name: 'details',
    meta: 'Узнать подробнее',
    component: () => import('../views/details.vue')
  },
  {
    path: '/page-1',
    name: '/page-1',
    meta: 'Об отеле',
    component: () => import('../views/textpage.vue')
  },
  {
    path: '/page-text',
    name: '/page-text',
    meta: 'Об отеле',
    component: () => import('../views/infohotel.vue')
  },
  {
    // path: '*',
    path: '/:pathMatch(.*)*',
    name: 'pageNotFound',
    meta: 'Ошибка 404',
    component: () => import('../views/error.vue')
  },
 
]

const router = new VueRouter({
  routes,
  scrollBehavior() {
    // always scroll to top of page on route change
    return {
      x: 0, y: 0
    }
  }
})

router.beforeEach((to, from, next)=>{
  if (from.path === to.path){
    return
  }
  addPlaceToUrl(to, next, router)
})

// Определяем глобальный перехватчик для всех маршрутов (routes) перед их обработкой.
router.beforeEach((to, from, next) => {
  
  // Массив маршрутов, которые считаются сервисными.
  const serviceRoutes = ['orderstatus', 'delivery'];
  
  // Добавляем свойство $isServiceRoute в прототип Vue.
  // Определяем, является ли текущий маршрут одним из сервисных маршрутов.
  Vue.prototype.$isServiceRoute = serviceRoutes.some(route => to.path.includes(route));
  
  // Продолжаем обработку маршрута.
  next();
});

// router.beforeEach((to, from, next) => {
//
//   // if (router.app.socket) {
//   //   router.app.socket.emit('nav:go', { token:localStorage.getItem('token'), data: to.name })
//   // }
//
//   // let user = router.app.user,
//   //     isLogged = false;
//
//   // if (!user && localStorage.user) {
//   //   user = JSON.parse(localStorage.user);
//   // }
//
//
//   // if (user) {
//   //     isLogged = true;
//   // }
//
//
//   // if (to.name == 'login') {
//   //   if (isLogged) {
//   //     console.log('utype', user.type)
//   //     next({
//   //       path: '/orders'
//   //     })
//   //   } else {
//   //     next()
//   //   }
//   // }
//
//
//   // if (user == null || !isLogged) {
//   //   next({
//   //     path: '/',
//   //     params: { nextUrl: to.fullPath }
//   //   })
//   // } else {
//
//   //   next();
//   // }
//   next()
// });

export default router
