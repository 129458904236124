/**
 * Misc helpers used throughout the app
 */

export function isProd() {
	// unfortunately we don't have ENV variable determining actual environment,
	// so we'll rely on current hostname
	return window.location.hostname.startsWith( 'vitrina' )
}

export function setCookie( name, value, days ) {
	let expires = ''
	if ( days ) {
		let date = new Date()
		date.setTime( date.getTime() + ( days * 24 * 60 * 60 * 1000 ) )
		expires = '; expires=' + date.toUTCString()
	}
	document.cookie = name + '=' + ( value || '' ) + expires + '; path=/'
}

export function getCookie( name ) {
	let matches = document.cookie.match( new RegExp( '(?:^|; )' + name.replace( /([\\.$?*|{}\\(\\)\\[\]\\\\/\\+^])/g, '\\$1' ) + '=([^;]*)' ) )
	return matches
	       ? decodeURIComponent( matches[ 1 ] )
	       : undefined
}

export function declOfNum( value, words ) {
	value     = Math.abs( value ) % 100
	const num = value % 10
	if ( value > 10 && value < 20 ) {
		return words[ 2 ]
	}
	if ( num > 1 && num < 5 ) {
		return words[ 1 ]
	}
	if ( num === 1 ) {
		return words[ 0 ]
	}
	return words[ 2 ]
}

export function isCancelFee( orderRoom = '' ) {
	const place = getPlace()
	const hotel    = JSON.parse( localStorage.getItem( `hotel_${place}` ) )
	const userRoom = localStorage.getItem( `guest_number_${place}` )
	
	const userRoomStr  = String( userRoom ).toLocaleLowerCase().trim()
	const orderRoomStr = String( orderRoom ).toLocaleLowerCase().trim()
	const room         = orderRoomStr || userRoomStr || ''
	
	const settingType  = hotel?.settings?.cancel_fee_type || null
	const settingValue = hotel?.settings?.cancel_fee_value || null
	
	const isCancelFee  = settingType === 'room' && settingValue === 'pickup'
	const isRoomPickUp = room === 'pickup'
	
	return isCancelFee && isRoomPickUp
}

export function convertTimeStringToMinutes( time ) {
	const [ hours, minutes ] = time.split( ':' ).map( Number )
	return hours * 60 + minutes
}

export function getURLParameterByName( name ) {
	let url      = window.location.href
	const origin = window.location.origin
	
	try {
		// Разделяем URL на основную часть и хеш
		let [ baseUrl, hash ] = url.split( '#' )
		if ( hash ) {
			// Проверяем, есть ли параметры в хеше
			if ( hash.includes( '?' ) ) {
				[ baseUrl, hash ] = hash.split( '?' )
			}
			// Создаем новый URL объект с хешем в виде строки параметров
			url = `${ origin }${ baseUrl }?${ hash }`
		}
		const parsedUrl = new URL( url )
		return parsedUrl.searchParams.get( name )
	}
	catch ( e ) {
		return null
	}
}

export function getPlace() {
	return getURLParameterByName( 'place' ) || getCookie( 'object_place' ) || ''
}

export function getGuestNumber() {
	const place = getPlace()
	return getURLParameterByName( 'number' ) || localStorage.getItem( `guest_number_${place}` ) || ''
}

export function addPlaceToUrl( to, next, router ) {
	const place = getPlace()
	if ( !to.query.place && place ) {
		const newQuery = { ...to.query, place }
		router.push( {
			...to,
			query: newQuery,
			replace: true
		} )
	}
	else {
		next()
	}
}

export function removeLocalStorageItemsWithPlace(place) {
	// Проходим по всем ключам в localStorage
	for (let i = 0; i < localStorage.length; i++) {
		const key = localStorage.key(i);
		
		const notRemove = [
			`cart_${ place }`,
			`guest_token_${ place }`,
			`guest_number_${ place }`
		]
		// Проверяем, содержит ли ключ подстроку '_place'
		if (key.includes(`_${place}`) && !notRemove.includes(key)) {
			localStorage.removeItem(key);
			// Поскольку мы изменили localStorage, необходимо уменьшить индекс на 1
			i--;
		}
	}
}

export function initParams(ver = 1 || 2 || 3){
	const LS_GUEST_NUMBER = 'guest_number';
	
	const func = {
		1: ()=>{
			const date  = new Date()
			// ищем параметр в cookies, соответсвующий объекту
			let name    = 'object_place'
			let matches = document.cookie.match( new RegExp( '(?:^|; )' +
				name.replace( /([\\.$?*|{}\\(\\)\\[\]\\\\/\\+^])/g, '\\$1' ) + '=([^;]*)' ) )
			
			let place = matches ? decodeURIComponent( matches[ 1 ] ) : undefined
			
			// ищем в запросе параметр объекта
			let queryName = 'place'
			let regex     = new RegExp( '[?&]' + queryName + '(=([^&#]*)|&|#|$)' )
			let results   = regex.exec( window.location.href )
			
			let placeQuery
			if ( results && results[ 2 ] ) {
				placeQuery = decodeURIComponent( results[ 2 ].replace( /\+/g, ' ' ) )
			}
			
			// ищем в запросе параметр номера гостя
			let queryNameGuestNumber = 'number'
			let regexGuestNumber     = new RegExp( '[?&]' + queryNameGuestNumber + '(=([^&#]*)|&|#|$)' )
			let resultsGuestNumber   = regexGuestNumber.exec( window.location.href )
			
			// получаем значение номера гостя из строки запроса
			let placeQueryGuestNumber
			if ( resultsGuestNumber && resultsGuestNumber[ 2 ] ) {
				placeQueryGuestNumber = decodeURIComponent( resultsGuestNumber[ 2 ].replace( /\+/g, ' ' ) )
			}
			
			// если в cookies значение не найдено, то берем значение из запроса
			if ( !place ) {
				if ( placeQuery && placeQuery !== place ) {
					place           = placeQuery
					document.cookie = name + '=' + place + ';expires=' + ( date.setTime( date.getTime() + ( 7 * 24 * 60 * 60 * 1000 ) ) ) + ';path=/'
					localStorage.clear()
				}
				
			}
			else {
				// если в cookies параметр есть и в запросе есть, то приоритет за запросом
				if ( placeQuery && placeQuery !== place ) {
					place           = placeQuery
					document.cookie = name + '=' + place + ';expires=' + ( date.setTime( date.getTime() + ( 7 * 24 * 60 * 60 * 1000 ) ) ) + ';path=/'
					localStorage.clear()
				}
			}
			
			// console.log( 'PLACE  : ', placeQuery, placeQueryGuestNumber )
			
			if ( placeQuery ) {
				let guestNumber = localStorage.getItem( LS_GUEST_NUMBER )
				
				// console.log( 'PLACE  : ', guestNumber, placeQueryGuestNumber )
				
				// если в ЛС уже есть номер,
				// а в запросе номер нет,
				// но присутствует идентификатор объекта
				// то стираем номер из ЛС
				if ( guestNumber && !placeQueryGuestNumber ) {
					localStorage.removeItem( LS_GUEST_NUMBER )
				}
				
				// если в запросе есть и объект и номер,
				// то сохраняем новый номер в ЛС
				if ( placeQuery && placeQueryGuestNumber ) {
					localStorage.setItem( LS_GUEST_NUMBER, placeQueryGuestNumber )
				}
				
			}
		},
		2: ()=>{
			const NAME_PARAMS = {
				place: {
					local: 'object_place',
					query: 'place'
				},
				number: {
					local: 'guest_number',
					query: 'number'
				}
			}
			
			const localData = {
				place: getCookie( NAME_PARAMS.place.local ),
				number: localStorage.getItem( NAME_PARAMS.number.local )
			}
			const queryData = {
				place: getURLParameterByName( 'place' ),
				number: getURLParameterByName( 'number' )
			}
			
			// Определение значения place
			let place = localData.place // Устанавливаем начальное значение place из cookie
			
			// Проверка, если значение в cookie отсутствует или отличается от значения в URL
			if ( !localData.place || ( !!queryData.place && queryData.place !== localData.place ) ) {
				place = queryData.place // Обновляем значение place из URL
				setCookie( NAME_PARAMS.place.local, place, 7 ) // Устанавливаем новое значение cookie на 7 дней
				localStorage.clear() // Очищаем localStorage
			}
			
			if ( queryData.place ) { // Если значение места присутствует в URL
				// Если номер гостя присутствует в localStorage, но отсутствует в URL, удаляем его из localStorage
				if ( localData.number && !queryData.number ) {
					localStorage.removeItem( NAME_PARAMS.number.local )
				}
				
				// Если номер гостя присутствует и в URL, и значение места тоже присутствует, обновляем номер гостя в
				// localStorage
				if ( queryData.place && queryData.number ) {
					localStorage.setItem( NAME_PARAMS.number.local, queryData.number )
				}
			}
		},
		3: ()=>{
			const NAME_PARAMS = {
				place: {
					local: 'object_place',
					query: 'place'
				}, // Объект
				number: {
					local: 'guest_number',
					query: 'number'
				}, // Номер комнаты/столика гостя в объекте
			}
			
			const localPlace = getCookie( NAME_PARAMS.place.local )
			const localData = {
				place: localPlace,
				number: localStorage.getItem( `${NAME_PARAMS.number.local}_${localPlace}` )
			}
			const queryData = {
				place: getURLParameterByName( 'place' ),
				number: getURLParameterByName( 'number' )
			}
			
			// Получаем значение place по приоритету (query,cookie)
			const place = queryData.place || localData.place || ''
			if ( place ) {
				// Всегда обновляем cookie
				setCookie( NAME_PARAMS.place.local, queryData.place, 7 )
				removeLocalStorageItemsWithPlace( place )
			}
			
			// Если значение place есть в query
			if ( queryData.place ) {
				
				// Если number есть в ЛС, но отсутствует в query, удаляем его из ЛС
				localData.number && !queryData.number && localStorage.removeItem( `${NAME_PARAMS.number.local}_${queryData.place}` )
				
				// Если place и number есть в query, то обновляем number в ЛС
				queryData.place && queryData.number && localStorage.setItem( `${NAME_PARAMS.number.local}_${queryData.place}`, queryData.number )
				
			}
		}
	}
	
	func[ver]()
}
