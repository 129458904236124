export default {
	'translation': {
		'common': {
			'backhome': 'Вернуться на главную',
			'free': 'Бесплатно',
			'checkout': 'Оформить заказ',
			'yes': 'Да',
			'no': 'Нет',
			'remove': 'Удалить',
			'nodepartment': 'Без отдела',
			'back': 'Вернуться'
		},
		'index': {
			'kvanbanner': {
				'title': 'Квань, горнолыжный комплекс',
				'skipass': 'Ски-пассы',
				'tubing': 'Тюбинг',
				'learn': 'Обучение катанию',
				'rent': 'Прокат'
			},
			'oos': {
				'notworking': 'На данный момент объект не работает.',
				'schedule_from': 'Часы работы объекта с {{from}}',
				'schedule': 'Часы работы объекта с {{from}} до {{to}}'
			}
		},
		'itempage': {
			'addtocart': 'в корзину',
			'details': 'Подробнее',
			'scheduleservice': 'Выбрать',
			'scheduleservice_cancel': 'Отменить',
			'timebooked': 'забронировано',
			'incart': 'в корзине',
			'selecttime': 'Выберите время',
			'timebooked_close': 'Запись на текущий день уже завершена'
		},
		'errorpage': {
			'error': 'Ошибка',
			'pagenotfound': 'Такая страница не найдена :(',
			'attention': 'Внимание!',
			'paymentfailed': 'Оплата не прошла!',
			'paymentretry': 'Попробовать еще раз',
			'backtoorder': 'Вернуться к заказу'
		},
		'checkout': {
			'yourorder': 'Заказ',
			'vat': 'НДС',
			'servicefee': 'Работа сервиса',
			'servicefeeinfo': 'Для поддержания работы сервиса взимается сервисный сбор в размере <b>{{amount}}</b>. Сбор покрывает расходы на обработку заказа, поддержку клиентов, улучшения работы и доставку товара или услуг до покупателя.',
			'total': 'Общая сумма',
			'alcowarn': 'Оплата алкоголя осуществляется только при получении заказа, через терминал или наличными. <br/>Доставка - в пределах территории объекта',
			'form': {
				'howtopay': 'Как вам удобно оплатить?',
				'room': 'Номер',
				'name': 'Имя',
				'phone': 'Номер телефона',
				'phoneplaceholder': 'Введите номер телефона',
				'note': 'Пожелания',
				'placeorder': 'Заказать',
				'phonelengtherror': 'Заполните номер телефона',
				'pickup': 'Самовывоз'
			},
			'item': {
				'timeslot': 'с {{from}} до {{to}}'
			},
			'privacypolicy': {
				'agree': 'Вы соглашаетесь',
				'withpolicy': 'с политикой конфиденциальности и офертой'
			},
			'payment': {
				'online': 'Онлайн, картой',
				'cash': 'Наличными',
				'card': 'Терминал, картой'
			},
			'paymenttype': 'Оплата',
			'noorders': 'Заказов еще нет',
			'pagetitle': 'Оформление заказа',
			'options': 'Опции',
			'confirmdelete': 'Вы точно хотите удалить весь заказ?'
		},
		'checkorder': {
			'confirmcancel': 'Вы уверены что хотите отменить заказ?',
			'order': 'Заказ',
			'payment': 'Оплата',
			'paid': 'Оплачено',
			'notpaid': 'Не оплачено',
			'changepayment': 'Возможно изменить способ оплаты',
			'paymenttype': 'Способ оплаты',
			'timelefttopay': 'Осталось время до оплаты',
			'pay': 'Оплатить',
			'cancel': 'Отменить'
		},
		'ordersuccess': {
			'orderaccepted': 'Ваш заказ принят',
			'orderno': 'Номер заказа # {{ orderID }}',
			'trackorder': 'Отследить заказ',
			'receiptlink': 'Ссылка на ваш ОФД чек оплаты',
			'pagetitle': 'Заказ #{{ orderID }}'
		},
		'bannercarousel': {
			'demohotel': {
				'freeday': 'День проживания в подарок',
				'romantik': 'Романтический ужин'
			}
		},
		'footer': {
			'copyright': 'STAYDAY ©️{{ year }} - Сервис онлайн витрины',
			'privacypolicy': 'Политика конфиденциальности и оферта',
			'notadvert': 'Все материалы на сайте носят информационный характер и не являются рекламой'
		},
		'cookiesmsg': {
			'datausage': 'Использование данных',
			'disclaimer': 'Использует файлы cookies и сервисы сбора технических данных для обеспечения работоспособности и улучшения качества обслуживания.',
			'agree': 'Соглашаюсь'
		},
		'foradult': {
			'is18': 'Вам уже есть 18 лет?',
			'disclaimer': 'Раздел содержит информацию не рекомендованную для лиц, не достигших совершеннолетнего возраста.'
		},
		'orderstatus': {
			'statusword': 'Статус',
			'status': {
				'new': 'Новый',
				'split': 'Разделен',
				'inwork': 'В работе',
				'processing': 'Обработка',
				'ready': 'Готов к выдаче',
				'delivered': 'В доставке',
				'completed': 'Доставлено',
				'canceled': 'Отменен',
				'confirmed': 'Подтверждено'
			},
			'servicebytime': {
				'status': {
					'new': 'Новая',
					'processing': 'Обработка',
					'confirmed': 'Подтверждена',
					'inwork': 'В работе',
					'completed': 'Выполнена',
					'canceled': 'Отменена'
				}
			}
		},
		'textpage': {
			'abouthotel': 'Об отеле',
			'texts': {
				'22': `
          <p>SK Royal Hotel Kaluga (ex. Kwan) – загородный отель в 10 минутах езды от центра г. Калуги и в двух часах от
            Москвы.</p>

          <p>Само здание является уникальным архитектурным сооружением, так как в зимнее время года на крыше начинается
            основной спуск горнолыжного комплекса «Квань». Вокруг открываются живописные виды на дельту Оки.</p>

          <p>Отель SK Royal Калуга (ex. Kwan) предлагает своим гостям полный спектр услуг для комфортного пребывания и
            успешного ведения бизнеса:</p>

          <p>74 номера в основном корпусе, 7 бунгало и 2 гостевых дома; бесплатный Wi-Fi на всей территории; несколько
            банкетных площадок, вмещающих до 300 гостей, лобби-бар, зимнее кафе и летнюю веранду; конференц-залы,
            оснащенные современным оборудованием; охраняемую парковку на 300 мест.</p>

          <p>Два банных домика с русскими банями на дровяной топке вместимостью до 8 гостей, уютно расположились в лесном
            массиве.А также Спа-центр с тренажерным залом, сауной, турецкой баней и бассейном.</p>
        `,
				'68': `
          <p>DemoHotel - это пятизвездочный отель, расположенный в самом сердце города. Это идеальное решение для отдыха любого формата: в шаговой доступности располагаются главные достопримечательности, развлекательные и бизнес-центры, а также потрясающие по красоте места для прогулок</p>

          <p>Наш отель предлагает превосходные условия проживания и широкий выбор номеров различных категорий, оформленных
            в современном стиле с изысканным декором и удобствами высшего качества. В каждом номере предусмотрена опция
            24-часового обслуживания, чтобы гарантировать комфорт и удовлетворение потребностей наших гостей.</p>

          <p>Мы гордимся нашими ресторанами высокого класса, шеф-повара которых удовлетворят любой гастрономический
            вкус независимо от того, предпочитаете ли вы классическую кухню или экзотические блюда. Наш персонал с
            радостью предложит вам лучшие вина и напитки, чтобы вы могли полностью насладиться пребыванием. Каждое
            утро в нашем главном ресторане подается завтрак по системе Шведский стол с широким и разнообразным выбором.
          </p>

          <p>Вы можете отдохнуть в SPA-центре, где Вам предложат широкий выбор разнообразных процедур для полного
            расслабления и восстановления энергии. Вы можете насладиться массажем, финской сауной или хаммамом.
            Наш дружелюбный и профессиональный персонал всегда готов позаботиться о вашем благополучии.</p>

          <p>Для тех, кто хочет поддерживать физическую форму во время пребывания, наше фитнес-центр обеспечит
            Вас первоклассными тренажерами и качественными персональными тренировками. У нас есть все необходимое
            для того, чтобы вы оставались в отличной форме, даже во время путешествия.</p>

          <p>Мы с гордостью предлагаем нашим гостям высокий стандарт гостеприимства во всех наших услугах. Наш
            персонал обеспечивает индивидуальный подход к каждому гостю, всегда готовый удовлетворить любое их
            пожелание или потребность. Благодаря нашему внимательному обслуживанию, ваше пребывание у нас станет
            незабываемым.</p>
        `,
				'77': `
          <div class="mb-4">
            <p class="fw-bold m-0">Какие типы заказа доступны</p>
            <span>Вы можете заказать еду и напитки через QR-код и официант принесет ваш заказ к столу или сделать заказ
              самостоятельно на стойке ресторана.</span>
          </div>
          <div class="mb-4">
            <p class="fw-bold m-0">Хочу заказать из нескольких корнеров сразу. Это возможно?</p>
            <span>Да! Вы можете сделать сборный заказ из любых корнеров одновременно. Добавляйте желаемые блюда в корзину и
              оплачивайте онлайн.</span>
          </div>
          <div class="mb-4">
            <p class="fw-bold m-0">У меня возникли сложности с оформлением заказа. Куда обращаться?</p>
            <span>Пожалуйста, свяжитесь с менеджером доставки по номеру +79308482525</span>
          </div>
          <div class="mb-4">
            <p class="fw-bold m-0">Как заказать алкогольные напитки?</p>
            <span>Здесь Вы можете ознакомиться с алкогольной картой корнеров
              «Бар, вино и устрицы», «Bebeer». Обращаем Ваше внимание, заказ алкогольных напитков осуществляется на барах.
            </span>
          </div>
          <div class="mb-4">
            <p class="fw-bold m-0">Работа сервиса</p>
            <span>Автоматически добавляется сервисный сбор в размере 10% от суммы заказа.</span>
          </div>
        `,
				'82': `
          <p>Отель в Волгограде &laquo;Южный&raquo; прекрасно расположен в деловом центре Волгограда и одновременно в
            историческом районе.
            Это уникальное сочетание истории города и современного здания отеля отражено и в дизайне интерьера.</p>

          <p>В шаговой доступности от отеля расположены: красивый парк, Ворошиловский торговый центр,
            торговая галерея, кинотеатры &laquo;Киномакс&raquo; и &laquo;Пять звезд&raquo;.</p>

          <p>190 номеров, оформленных со вкусом и любовью дали все основания трехзвездочному отелю претендовать
            на статус популярного городского отеля среди гостей Волгограда.</p>
        `,
				'empty': 'Информация об объекте заполняется...'
			}
		}
	}
}
